import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {RequestService} from "pemikdev-project-lib";
import {UserModel} from "pemikdev-project-lib/model/user-model";

const GET_USERS = '/api/auth/GetUsersAnonim';
const GET_USER_WITH_ROLES = '/api/auth/GetUserWithRole';

@Injectable({
  providedIn: 'root'
})
export class ExtendedUserService {

  constructor(private requestService: RequestService) { }

  getUsersAnonim(): Observable<UserModel[]> {
    return this.requestService.get<any>(GET_USERS).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  getUserWithRole(): Observable<UserModel> {
    return this.requestService.get<any>(GET_USER_WITH_ROLES).pipe(
      map(res => {
        return res.result;
      })
    );
  }
}
