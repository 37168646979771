// USA
export const locale = {
  lang: 'hu',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      DASHBOARD: 'Kezdőlap',
      USERS: 'Felhasználók',
      USER_ROLES: 'Felhasználói szerepkörök',
      BUILDINGS: 'Épületek',
      EXTRA: 'Rendszer adminisztráció',
      BUILDING_CATEGORIES: 'Épület kategóriák',
      EMISSION_FACTOR: 'Tüzelőanyag-kibocsátási tényezők',
      BUILDING_CONSUMPTION_DATA_TYPES: 'Fogyasztási adattípusok',
      CONNECTION_DATA_TYPES: 'Épület-felhasználó kapcsolat típusok',
      MUNICIPALITIES: 'Önkormányzatok',
      COMPANIES: 'Vállalatok',
      ENERGETIC_SIMULATION_MASTER_DATA: 'Energetikai szimuláció törzsadatok'
    },
    USER_MENU: {
      DASHBOARD: 'Kezdőlap',
      MAIN_DATA: 'Törzsadatok',
      ADD_DATA: 'Adatok megadása',
      ADD_SETTLEMENT_DATA: 'Települési adatok',
      ADD_BUILDINGS: 'Épületek megadása',
      ADD_GOALS: 'Célok megadása',
      ADD_POPULATIONS_DATA: 'Lakossági adatok megadása',
      ADD_COMPANY_DATA: 'Vállalkozások releváns adatainak a megadása',
      ALARM_LEVELS: 'Riasztási szintek beállítása',
      PUBLIC_DATA_IMPORT: 'Publikus adatok importálása',
      DOCUMENTS: 'Dokumentumok',
      MUNICIPALITY_DOCUMENTS: 'Önkormányzati',
      VIEW_STRATEGIES: 'Hatályos és régi stratégiák megtekintése',
      NATIONAL: 'Nemzeti',
      INTERNATIONAL: 'Nemzetközi',
      OTHER: 'Egyéb',
      ENVIRONMENTAL_CONDITION: 'Környezeti állapot',
      SETTLEMENT_DATA: 'Települési adatok megtekintése',
      SETTLEMENT_SETTINGS: 'Település (beállítások)',
      AIR_QUALITY: 'Levegőminőség',
      WATER_QUALITY: 'Vízminőség',
      WEATHER_DATA: 'Klímaállapot, időjárási adatok',
      ENERGY_UTILITIES: 'Energetika és közmű',
      BUILDINGS: 'Épületek',
      DATA_SHEET: 'Adatlap',
      VIEW_DATA: 'Adatok megtekintése',
      DATA_IMPORT: 'Adat import',
      CONFIGURATION: 'Konfiguráció',
      TRANSPORT: 'Közlekedés',
      STRATEGIC_PLANNER: 'Stratégiai tervező',
      SECAP: 'SECAP',
      ADD_OBJECTIVES: 'Célok rögzítése',
      CHECK_OBJECTIVES: 'Célok teljesülésének monitorozása',
      CLIMATE_STRATEGY: 'Klímastratégia',
      ENERGY_STRATEGY: 'Energiastratégia',
      ITS: 'ITS',
      SUMP: 'SUMP',
      UNDER2: 'Uder2',
      SIMULATIONS: 'Szimulációk - Energetika',
      SIMULATIONS_RESILIENCE: 'Szimulációk - Ellenálló képesség',
      DEVELOPMENT_OPTIONS: 'Fejlesztési opciók',
      SETTINGS: 'Beállítások',
      SIMULATION: 'Szimuláció',
      DATA_PUBLICATION: 'Adatpublikálás',
      DATA_SETTINGS: 'Lekérdezés beállítása/mentése/szerkesztése',
      QUERY_DATA: 'Adatok lekérdezése',
      SHOW_PUBLIC_DATA: 'Publikus adatok (oldal) megtekintése',
      PUBLIC_DATA_SETTINGS: 'Publikus adatok (engedélyezés/tiltás/megjegyzések)',
      SHOW_DATA: 'Adatok megtekintése',
      EXPORT: 'Exportálás',
      REPORT: 'Riport „varázsló"',
      HELP_AND_SEARCH: 'Segédlet és keresés',
      PROJECT_PLANNER: "Projekttervező (később)",
      ENVIRONMENTAL_DATA: 'Környezeti adatok',
      CONSUMPTION_DATA: 'Fogyasztási, termelési adatok',
      PROJECTS: 'Pályázatok/ projektek',
      SMART_MANAGEMENT: 'SMART management',
      LIBRARY: 'Könyvtár',
      SOCIAL_DIALOG: 'Társadalmi párbeszéd',
      ENERGY_CONSUMPTION_OPTIMALISATION: 'Energiafelhasználás optimalizálása',
      INTELLIGENT_TIMING: 'Intelligens időzítés és automatizálás',
      ENERGY_PRODUCTION: 'Energiatermelés és tárolás optimalizálása',
      DATA_ANALYSIS: 'Adatelemzés',
      REZILIENCIA: 'Reziliencia',
      ENVIRONMENTAL_PROTECTION: 'Környezetvédelmi program',
      SUSTAINABILITY: 'Fenntarthatósági stratégia',
      ELECTRIC_ENERGY_CONSUMPTION: 'Energiafogyasztás (elektromos áram)',
      GAS_ENERGY_CONSUMPTION: 'Energiafogyasztás (földgáz)',
      WATER_CONSUMPTION: 'Vízfogyasztás',
      WASTE_MANAGEMENT: 'Hulladéktermelés',
      ENERGY_GENERATION: 'Energiatermelés',
      SOIL_QUALITY: 'Talajminőség',
      NOISE_POLLUTION: 'Zajszennyezettség'
    },
    TOPBAR: {
      BACK_BUTTON: 'Vissza'
    },
    PROFILE_DROPDOWN: {
      MY_PROFILE: 'Profilom',
      LANGUAGE: 'Nyelv',
      LOGOUT: 'Kijelentkezés',
      HI: 'Üdv, '
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} megadása kötelező',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      MAX_LENGTH: '{{name}} maximális hossza {{max}} karakter!',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
      EMAIL: 'E-mail cím megadása kötelező az következő formátumban: nev@valami.hu!',
      TAX_NUMBER: 'Adóazonosító szám megadása kötelező! Maximum 10 karakter hosszúságú lehet.',
      USER_PASSWORD: 'Jelszó megadás kötelező! A jelszó minimum hossza 6 karakter! A jelszó speciális karaktereket, számokat, kis és nagybetűt kell tartalmaznia!',
      EMAIL_FORMAT: 'E-mail formátum szükséges',
      PASSWORD_PATTERN: 'A jelszó  kisbetűt, nagybetűt, számot és speciális karaktert tartalmazó 6 karakteres szöveg!',
      PASSWORD_NOT_MATCH: 'A két jelszó nem egyezik!',
      MIN: '{{name}} minimális értéke {{min}}',
      MAX: '{{name}} maximális értéke {{max}}',
      WHOLE_NUMBER: '{{name}} értéke csak egész szám lehet!',
      WEBSITE_PATTERN: 'A cím formátuma nem megfelelő'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    GENERAL: {
      SEARCH: 'Keresés',
      RELOAD: 'Újratöltés',
      ADD: 'Hozzáadás',
      DELETE: 'Törlés',
      UPDATE: 'Módosítás',
      SAVE: 'Mentés',
      CREATE: 'Létrehozás',
      CANCEL: 'Mégsem',
      WARNING: 'Figyelem!',
      LOGOUT_ON_CHANGE: 'Módosítást követően új bejelentkezés szükséges!',
      ACTIONS: 'Műveletek',
      VIEW: 'Megtekintés'
    },
    USERS: {
      USER_LIST: {
        NAME: 'Név',
        USERNAME: 'Felhasználónév',
        TAX_NUMBER: 'Adóazonosító',
        STATUS: 'Állapot',
        MODIFIED: 'Módosítva',
        ACTIONS: 'Műveletek',
        ACTIVE: 'Aktív',
        INACTIVE: 'Inakítv',
        MODIFY: 'Módosítás',
        ROLES: 'Szerepkörök',
        LOCK: 'Zárolás',
        UNLOCK: 'Feloldás',
        DETAILS: 'Részletek'
      },
      CREATE_UPDATE: {
        TITLE: 'Új felhasználó',
        SUBTITLE: 'A megjelenítendő neve',
        EMAIL: 'E-mail címe',
        TAX_NUMBER: 'Adóazonosító',
        DATA: 'Adatok',
        USERNAME: 'Felhasználónév',
        DISPLAY_NAME: 'Megjelenítendő név',
        CONFIRM_PASSWORD: 'Megerősítő jelszó',
        LOGIN_METHOD: 'Bejelentkezési mód'
      },
      ROLE: {
        TAX_NUMBER: 'Adóazonosító',
        ROLE_NOT_FOUND: 'Nincs hozzárendelve szerepkör',
        ASSIGN_ROLE: 'Szerepkör hozzárendelése',
        SELECT_ROLE: 'Válasszon a legördülő listából szerepkört',
        ROLES: 'Szerepkörei'
      }
    },
    ROLES: {
      LIST: {
        ADD_CARD_TITLE: 'Új felhasználói szerepkör hozzáadása',
        ROLE_COUNT: 'A szerepkörhöz tartozó jogosultságok száma: ',
        MORE: ' további...',
        ROLE_NOT_FOUND: 'Ehhez a szerepkörhöz nem tartozik jogosultság'
      },
      CREATE_UPDATE: {
        ADD_ROLE: 'Új szerepkör',
        EDIT_ROLE: 'A szerepkör módosítása',
        ENABLE_EDIT: 'Módosítás engedélyezése',
        ROLE_NAME: 'Szerepkör neve',
        DISPLAY_NAME: 'Megjelenítendő név',
        ROLES: 'Jogosultságai',
        ROLE_NOT_FOUNND: 'Nincs hozzárendelve jogosultság',
        ASSIGN_ROLE: 'Jogosultság hozzáadása a szerepkörhöz',
        SELECT_FROM_LIST: 'Válasszon a legördülő listából jogosultságot',
        ADD_TO_LIST: 'Listához ad'
      },

    },
    BUILDINGS: {
      LIST: {
        ADD_TITLE: 'Új épület létrehozása',
        CONTACTS: 'Kapcsolattartási adatok',
        CATEGORY: 'Kategória',
        MAINTAINER: 'Épület fenntartó',
        HEAD_OF_INSTITUTION: 'Vezető személye',
        BUILDINGS: 'Épületek',
        NAME: 'Név',
        ADDRESS: 'Cím',
        CONTACT: 'Kapcsolat'
      },
      CREATE_UPDATE: {
        ADD_TITLE: 'Épület létrehozása',
        UPDATE_TITLE: 'Épület adatok módosítása',
        NAME: 'Épület/ intézmény megnevezése',
        INSTITUTION: 'Épület/ intézmény megnevezésének',
        CATEGORY: 'Épület kategória',
        IDENTIFIER: 'Azonosító',
        HEAD_OF_INSTITUTION: 'Épület/ intézményvezető neve',
        HEAD_OF_INSTITUTION_REQUIRED: 'Épület/ intézményvezető nevének',
        HEAD_OF_INSTITUTION_LENGTH: 'Épület/ intézményvezető neve',
        MAINTAINER: 'Épület/ intézményfenntartó neve',
        MAINTAINER_REQUIRED: 'Épület/ intézményfenntartó nevének',
        MAINTAINER_LENGTH: 'Épület/ intézményfenntartó neve',
        ADDRESS_DATA: 'Cím adatok',
        BASE_DATA: 'Alapadatok',
        COUNTRY: 'Ország',
        ZIP: 'Irányítószám',
        CITY: 'Város',
        ADDRESS: 'Közterület jellege',
        ADDRESS_REQUIRED: 'Közterület jellegének ',
        ADDRESS_NUMBER: 'Házszám (emelet, ajtó)',
        CONTACTS: 'Kapcsolattartási adatok',
        CONTACT_TYPE: 'Mód',
        MOBILE: 'Mobil',
        PHONE: 'Telefon',
        EMAIL: 'Email',
        OTHER: 'Egyéb',
        CONTACT_VALUE: 'Elérhetőség',
        ADD_CONTACT: 'Kapcsolattartási adatok megadása',
        CONTACT_NAME: 'Név'
      }
    },
    BUILDING_CATEGORIES: {
      ADD_CATEGORY: 'Új kategória létrehozása',
      CATEGORY_NAME_LABEL: 'Adja meg a kategória nevét',
      CATEGORY: 'Kategória',
      CATEGORY_NAME: 'Kategória neve',
      UPDATE_CATEGORY_BUTTON: 'Kategória módosítása'
    },
    BUILDING_CONSUMPTION_DATA_TYPES: {
      ADD: 'Új adattípus létrehozása',
      UPDATE: 'Fogyasztási adattípus módosítása',
      TYPE_NAME_LABEL: 'Adja meg a típus nevét',
      TYPE_NAME: 'Típus neve',
      DATA_TYPE_NAME: 'Adatípus nevének ',
      ADD_MEASUREMENT_LABEL: 'Adja meg a mértékegységet',
      ADD_MEASUREMENT: 'Mértékegység',
      ADD_ICON: 'Ikon',
      ADD_CODE_NAME: 'Kódnév',
      CODENAME_PATTERN: 'Az angol ABC nagybetűit, illetve 0-9 számokat tartalmazhat.',
      ADD_EMISSION_FACTOR_CODE_NAME: 'Kibocsátási faktor kódneve',
      EMISSION_FACTOR_CODE_NAME_PATTERN: 'Az angol ABC betűit, illetve 0-9 számokat tartalmazhat.',
    },
    CARD_PAGINATOR: {
      ALL: 'Összesen',
      SEARCH: 'Keresés'
    },
    MY_PROFILE: {
      OVERVIEW: 'Áttekintés',
      CHANGE_PASSWORD: 'Jelszó módosítás',
      CHANGE_AVATAR: 'Profilkép módosítás',
      PERSONAL_INFORMATION: 'Személyes információk',
      USERNAME: 'Felhasználónév',
      DISPLAY_NAME: 'Megjelenítendő név',
      EMAIL: 'E-mail cím',
      COMMENT: 'Megjegyzés'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Jelszó módosítás',
      CURRENT_PASSWORD: 'Jelenlegi jelszó',
      NEW_PASSWORD: 'Új jelszó',
      CONFIRM_PASSWORD: 'Új jelszó mégegyszer',
      PASSWORD: 'Jelszó'
    },
    CHANGE_AVATAR: {
      TITLE: 'Profilkép'
    },
    BUILDING_NAVBAR: {
      BASE_DATA: 'Törzsadatok',
      CONSUMPTION_METER: 'Mérőórák',
      CONSUMPTION_DATA: 'Fogyasztási adatok',
      ENVIRONMENTAL_DATA: 'Környezet állapot adatok',
      TENDERS: 'Pályázatok',
      HELP: 'Segédlet',
      INDICATIONS: 'Jelzések'
    },
    CONSUMPTION_DATA_CHART: {
      NOT_FOUND: 'A {{name}} fogyasztási adat kategóriában az elmúlt egy évben nincsenek fogyasztási adatok!'
    },
    CONSUMPTION_DATA_LIST: {
      REFERENCE_DATE: 'Időszak',
      VALUE: 'Érték',
      STATUS: 'Státusz',
      CREATED_AT: 'Létrehozva',
      MODIFIED_AT: 'Utóljára módosítva',
      METER_ID: 'Mérőóra azonosító',
      METER_TYPE: 'Mérőóra típusa'
    },
    CREATE_UPDATE_CONSUMPTION_DATA: {
      DATE: 'Dátum',
      VALUE: 'Érték',
      MEASURING_METER: 'Mérőóra',
      TABLE_VIEW: 'Táblázatos nézet',
      DIAGRAM: 'Diagram',
      NO_PERMISSIONS_TITLE: 'Hiányzó jogosultságok',
      NO_PERMISSIONS_CONTEXT: 'Nincs megfelelő jogosultsága az épület fogyasztási adatok kezeléséhez!'
    },
    ERROR: {
      PAGE_NOT_FOUND_TITLE: 'Az oldal nem található!',
      PAGE_NOT_FOUND: 'A keresett oldal nem található!',
      GO_TO_HOMEPAGE: 'Vissza a főoldalra',
      PERMISSION_DENIED: 'Nem rendelkezik a szükséges jogosultságokkal!'
    },
    SMART_MANAGEMENT: {
      ENERGY_CONSUMPTION_OPTIMALISATION: "Energiafelhasználás optimalizálása",
      ENERGY_CONSUMPTION_OPTIMALISATION_TEXT: "A rendszer megkönnyíti az energiafelhasználás optimalizálását azáltal, hogy elemzi és összehasonlítja az energiafogyasztási adatokat. Az algoritmusok és a gépi tanulás segítségével a rendszer javaslatokat tehet az energiahatékonysági intézkedésekre, például az energiaigényes eszközök hatékonyabb üzemeltetésére vagy a fogyasztás csúcsidőszakokban való csökkentésére.",
      INTELLIGENT_TIMING: "Intelligens időzítés és automatizálás",
      INTELLIGENT_TIMING_TEXT: "A rendszer lehetőséget nyújt az időzített és automatizált beállításokra. Ez azt jelenti, hogy előre meghatározott időpontokban vagy események bekövetkeztével automatikus vezérlést és beállításokat hajthat végre. Például lehetőség van az épületek fűtésének és hűtésének automatikus szabályozására, valamint a világítás vagy más rendszerek be- és kikapcsolásának időzített vezérlésére.",
      ENERGY_PRODUCTION: "Energiatermelés és tárolás optimalizálása",
      ENERGY_PRODUCTION_TEXT: "A rendszer lehetőséget nyújthat az energia-termelés (pl. napelemek, szélerőművek) és energiatárolás (pl. akkumulátorok) optimalizálására. Az algoritmusok elemzik a környezeti adatokat (pl. napsugárzás, szélsebesség) és a fogyasztási adatokat, hogy meghatározzák az optimális energiaellátást és tárolást a fenntartható üzemeltetés érdekében.",
      DATA_ANALYSIS: "Adatelemzés",
      DATA_ANALYSIS_TEXT: "A rendszer lehetőséget nyújt az adatok részletes elemzésére és riportolására. Ez segít az energiamegtakarítási lehetőségek azonosításában, a hatékonyságértékelésben és a környezeti teljesítmény monitorozásában. A riportok és az analitikai eszközök lehetővé teszik a döntéshozók számára, hogy átfogó képet kapjanak az energiafelhasználásról és a fenntarthatósági célok eléréséről.",
      SIMULATIONS: "Szimulációk",
      SIMULATIONS_TEXT: "A rendszer képes lehet különböző környezeti hatások elemzésére, mint például a CO2-kibocsátás, a vízhasználat, az anyagfelhasználás stb. Ez segít az éghajlatváltozásra gyakorolt hatások nyomon követésében és az intézkedések kidolgozásában a fenntarthatósági célok elérése érdekében.",
    },
    CONSUMPTION_DATA_SUM: {
      DATE_INTERVAL_LABEL: 'Időszak kiválasztása',
      NO_CONSUMPTION_DATA_FOUND: 'Az önkormányzathoz nem tartoznak fogyasztási adatok!',
      DATE_PLACEHOLDER: 'Időszak kiválasztása...',
      X_TOOLTIP: 'Kiválasztott időszak törlése'
    },
    ENVIRONMENTAL_DATA: {
      DATE_INTERVAL_LABEL: 'Időszak kiválasztása',
      X_TOOLTIP: 'Kiválasztott időszak törlése',
      DATE_PLACEHOLDER: 'Időszak kiválasztása...',
      NO_ENVIRONMENTAL_DATA_FOUND: 'Az önkormányzathoz nem tartoznak környezeti adatok!',
      NO_BUILDING_ASSIGNED_TO_THE_MUNICIPALITY: 'Nincs épület hozzárendelve az önkörmányzathoz!'
    },
    LIBRARY: {
      LIBRARY_TITLE: 'Könyvtár',
      LIBRARY_TEXT: 'Ebben a menüpontban hozzáférhet a rendszer által biztosított dokumentumokhoz, útmutatókhoz és tudástárhoz. A könyvtárban helyezhetők el a már elfogadott, hatályos szakterületi stratégiák, akciótervek, programok. Itt találhatók a technikai leírások, használati útmutatók és egyéb segédanyagok is. Ez segít Önnek az információk könnyű elérésében és az optimális használatban.'
    },

    SIMULATIONS: {
      SIMULATION_NAME: 'Szimuláció megnevezése',
      BUILDING_NAME: 'Épület neve',
      CREATED_BY: 'Létrehozó',
      CREATED_AT: 'Létrehozás dátuma',
      ACTIONS: 'Műveletek',
      MODIFY: 'Módosítás',
      COPY: 'Másolás',
      START: 'Adatok és eredmények'
    },

    ENERGETIC_SIMULATION_MASTER_DATA: {
      AIR_EXCHANGE: 'Légcsereszám tervezési értékei nyáron,természetes szellőztetéssel',
      BUILDING_BOUNDARY_STRUCTURE_HEAT_TRANSFER_FACTOR: 'Épület határoló szerkezetének hőátadási tényezője',
      BUILDING_MATERIAL: 'Építőanyagok',
      ELECTRICAL_HEAT_PUMP_POWER_FACTOR: 'Elektromos üzemű hőszivattyúk teljesítménytényezője',
      GAS_OPERATION_HEAT_PUMP_POWER_FACTOR: 'Földgáz üzemű hőszivattyúk teljesítménytényezője',
      INDOOR_BOILER_POWER_FACTOR: 'Fűtött téren belül elhelyezett kazánok teljesítménytényezői',
      OUTDOOR_BOILER_POWER_FACTOR: 'Fűtött téren kívül elhelyezett kazánok teljesítménytényezői',
      PERFORMANCE_HEAT_INNACURACY_LOSS: 'Teljesítmény és hőigény illesztésének pontatlansága miatti veszteségek',
      PLANNING_DATA: 'Épület rendeltetése (fűtés szempontjából)',
      SOLID_BIOMASS_BURNING_AUX_ENERGY_DEMAND: 'Szilárd és biomasszatüzelés segédenergia igénye',
      SOLID_BIOMASS_BURNING_POWER_FACTOR: 'Szilárd és biomasszatüzelés teljesítménytényezője',
      THERMAL_RESISTANCE: 'Padlószerkezet hővezetési ellenállása',
      WALL_THERMAL_TRANSFER: 'Falszerkezet hőátbocsátási tényezője'
    }
  }
};
