import {Injectable} from '@angular/core';
import {RequestService} from "pemikdev-project-lib";
import { Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {CompanyModel} from "../model/company.model";
import {CompanyContactModel} from "../model/company-contact.model";

const READ_COMPANIES = '/api/company/read';
const READ_COMPANY_BY_ID = '/api/company/get/';
const CREATE_COMPANY = '/api/company/create';
const UPDATE_COMPANY = '/api/company/update';

const READ_MY_COMPANY_BY_ID = '/api/company/my/';
const READ_MY_COMPANIES = '/api/company/my';

const CREATE_CONTACT = 'api/company/contact/create';
const UPDATE_CONTACT = '/api/company/contact/update';
const DELETE_CONTACT = '/api/company/contact/';

const CREATE_MY_CONTACT = '/api/company/contact/my/';
const UPDATE_MY_CONTACT = '/api/company/contact/my/';
const DELETE_MY_CONTACT = '/api/company/contact/my/';

const READ_USER_CONNECTIONS = '/api/company/userConnections/';
const READ_MY_USER_CONNECTIONS = '/api/company/userConnections/my/';

const READ_BUILDING_CONNECTIONS = '/api/company/companyBuildings/';
const READ_MY_BUILDING_CONNECTIONS = '/api/company/companyBuildings/my/';

const CREATE_USER_CONNECTION = '/api/company/';
const CREATE_MY_USER_CONNECTION = '/api/company/my/';

const REMOVE_USER_CONNECTION = '/api/company/';
const REMOVE_MY_USER_CONNECTION = '/api/company/my/';

const CREATE_BUILDING_CONNECTION = '/api/company/';
const CREATE_MY_BUILDING_CONNECTION = '/api/company/my/';

const REMOVE_BUILDING_CONNECTION = '/api/company/building/';
const REMOVE_MY_BUILDING_CONNECTION = '/api/company/my/building/connection/';

const READ_BUILDINGS = '/api/building/connection/company';
const READ_MY_BUILDINGS = '/api/building/my/connection/company/';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companies: CompanyModel[];

  constructor(private requestService: RequestService) {
  }

  readCompanies(reload = false): Observable<CompanyModel[]> {
    if (this.companies && !reload) {
      return of(this.companies);
    }
    return this.requestService.get<any>(READ_COMPANIES).pipe(
      map(res => {
        this.companies = res.result;
        return res.result;
      })
    );
  }

  readMyCompanies(reload = false): Observable<CompanyModel[]> {
    if (this.companies && !reload) {
      return of(this.companies);
    }
    return this.requestService.get<any>(READ_MY_COMPANIES).pipe(
      map(res => {
        this.companies = res.result;
        return res.result;
      })
    );
  }

  readCompanyById(companyId: number): Observable<CompanyModel> {
    return this.requestService.get<any>(READ_COMPANY_BY_ID + companyId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyCompanyById(companyId: number): Observable<CompanyModel> {
    return this.requestService.get<any>(READ_MY_COMPANY_BY_ID + companyId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  createCompany(companyData: CompanyModel): Observable<CompanyModel> {
    return this.requestService.post<any>(CREATE_COMPANY, companyData).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  updateCompany(companyData: CompanyModel): Observable<CompanyModel> {
    return this.requestService.put(UPDATE_COMPANY, companyData);
  }

  createCompanyContact(companyData: any): Observable<CompanyContactModel> {
    return this.requestService.post(CREATE_CONTACT, companyData);
  }

  updateCompanyContact(companyData: CompanyContactModel): Observable<CompanyContactModel> {
    return this.requestService.put(UPDATE_CONTACT, companyData);
  }

  deleteCompanyContact(companyId: number, contactId: number): Observable<CompanyContactModel> {
    return this.requestService.delete(DELETE_CONTACT + companyId + '/contact/' + contactId);
  }

  createMyCompanyContact(companyId: number, companyData: any): Observable<CompanyContactModel> {
    return this.requestService.post(CREATE_MY_CONTACT + companyId, companyData);
  }

  updateMyCompanyContact(companyId: number, companyData: CompanyContactModel): Observable<CompanyContactModel> {
    return this.requestService.put(UPDATE_MY_CONTACT +companyId, companyData);
  }

  deleteMyCompanyContact(companyId: number, contactId: number): Observable<CompanyContactModel> {
    return this.requestService.delete(DELETE_MY_CONTACT + companyId + '/contact/' + contactId);
  }

  createUserConnection(companyId: number, userId: number): Observable<any> {
    return this.requestService.post(CREATE_USER_CONNECTION + companyId + '/user/' + userId + '/connectedTo', null);
  }

  createMyUserConnection(companyId: number, userId: number): Observable<any> {
    return this.requestService.post(CREATE_MY_USER_CONNECTION + companyId + '/user/' + userId + '/connectedTo', null);
  }

  removeUserConnection(companyId: number, userId: number): Observable<any> {
    return this.requestService.delete(REMOVE_USER_CONNECTION + companyId + '/connection/user/' + userId + '/type/connectedTo', null);
  }

  removeMyUserConnection(companyId: number, userId: number): Observable<any> {
    return this.requestService.delete(REMOVE_MY_USER_CONNECTION + companyId + '/connection/user/' + userId + '/type/connectedTo', null);
  }

  readCompanyUserConnections(municipalityId: number): Observable<any> {
    return this.requestService.get<any>(READ_USER_CONNECTIONS + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyCompanyUserConnections(municipalityId: number): Observable<any> {
    return this.requestService.get<any>(READ_MY_USER_CONNECTIONS + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyCompanyBuildingConnections(companyId: number): Observable<any> {
    return this.requestService.get<any>(READ_BUILDING_CONNECTIONS + companyId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  createBuildingConnection(companyId: number, buildingId: number): Observable<any> {
    return this.requestService.post(CREATE_BUILDING_CONNECTION + companyId + '/connection/' + buildingId, null);
  }

  createMyBuildingConnection(companyId: number, buildingId: number): Observable<any> {
    return this.requestService.post(CREATE_MY_BUILDING_CONNECTION + companyId + '/connection/' + buildingId, null);
  }

  removeBuildingConnection(connectionId: number): Observable<any> {
    return this.requestService.delete(REMOVE_BUILDING_CONNECTION + connectionId, null);
  }

  removeMyBuildingConnection(connectionId: number, companyId: number): Observable<any> {
    return this.requestService.delete(REMOVE_MY_BUILDING_CONNECTION + connectionId + '/' + companyId, null);
  }

  readBuildings(): Observable<any> {
    return this.requestService.get<any>(READ_BUILDINGS).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyBuildings(companyId: number): Observable<any> {
    return this.requestService.get<any>(READ_MY_BUILDINGS + companyId).pipe(
      map(res => {
        return res.result;
      })
    );
  }
}
