import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router,
} from '@angular/router';
import {AuthService, PermissionService} from "pemikdev-project-lib";
import {MenuConfig} from "../../../_metronic/layout/core/menu.config";
import {MenuItem} from "../../../smartek/model/menu.config";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  menuItems: Array<MenuItem>
  constructor(private pemikAuthService: AuthService, private router: Router, private permissionService: PermissionService) {
    this.menuItems =  MenuConfig.configs().items;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.filterUnathorizedPages(state);
    if ((this.pemikAuthService.isLogged()) || state.url.includes('error')) {
      return true;
    } else {
      this.pemikAuthService.logout();
      this.goLoginPage();
    }
    return false;
  }

  goLoginPage() {
    this.router.navigate(['auth']);
  }

  goMainpage() {
    this.router.navigate(['/municipality/-/dashboard']);
  }

  goBrowserErrorPage() {
    this.router.navigate(['error', 'browser']);
  }

  goUnathorizedErrorPage() {
    this.router.navigate(['error', '403']);
  }

  filterUnathorizedPages(state: RouterStateSnapshot) {
    if (this.menuItems && this.permissionService?.permissions?.length > 0 && this.pemikAuthService.isLogged()) {
      let menuItem: MenuItem
      for (const item of this.menuItems) { // aside
        if (item.submenu) {
          for (const submenuItem of item.submenu) {
            if (submenuItem.page === state.url) {
              menuItem = submenuItem;
            }
          }
        } else {
          if (item.page === state.url) {
            menuItem = item;
          }
        }
      }

      // console.log(menuItem);

      // @ts-ignore
      if (menuItem?.permission) {
        // @ts-ignore
        if (!this.permissionService.checkPermission(menuItem.permission)) {
          this.goUnathorizedErrorPage();
        }
      }
    }
  }
}
