import {Observable} from 'rxjs';

import {finalize, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService as PemikAuthService, LocalStorageService, WebSocketService} from "pemikdev-project-lib";
import {NotifierService} from "angular-notifier";
import {Router} from "@angular/router";

@Injectable()
export class HttpRequestInterceptorService implements HttpInterceptor {
	count = 0;

	constructor(private spinner: NgxSpinnerService,
				private localStorageService: LocalStorageService,
				private _wsService: WebSocketService,
				private notifierService: NotifierService,
				private pemikAuthService: PemikAuthService,
        private router: Router) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		setTimeout(() => this.spinner.show());
		this.count++;

		const token: string = this.pemikAuthService.getToken();

		if (token) {
			req = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)});
			this.delete_cookie('Identity.External');
		}


		if (!req.headers.has('Content-Type') && !req.headers.has('X-File-Upload')) {
			req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
		}
		req = req.clone({headers: req.headers.set('Accept', 'application/json')});
		req = req.clone({headers: req.headers.set('ResponseType', 'json')});
		req = req.clone({headers: req.headers.set('X-Requested-With', '')});
		req = req.clone({headers: req.headers.set('withCredentials', 'true')});

		if (this._wsService.clientWsId) {
			req = req.clone({headers: req.headers.set('X-Client-Ws-Id', this._wsService.clientWsId)});
		}

		return next.handle(req)
			.pipe ( tap (
				event => {
					if (event instanceof HttpResponse) {
						// console.log(event);
					}
				},
				error => {
					console.error('Error in interceptor service', error);
					const msg = this.extractMessage(error);
					this.notifierService.notify('error', msg);
				}),
				finalize(() => {
					this.count--;
					if (this.count === 0) {
						setTimeout(() => this.spinner.hide());
					}
				})
			);
	}

	delete_cookie(name: string) {
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	extractMessage(err: HttpErrorResponse): string {
      if (err.status === 401) {
        this.pemikAuthService.logout();
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      }
     const message = err.error.responseException.exceptionMessage;
     if (message === 'A felhasználó nem található!') {
       history.back();
     }
     return message;
	}
}
