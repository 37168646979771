import {MenuConfigModel} from '../../../smartek/model/menu.config'

export class MenuConfig {
  public static defaults: MenuConfigModel = {
    items: [
      {
        title: 'MENU.DASHBOARD',
        page: 'dashboard',
        icon: './assets/media/icons/duotune/art/art002.svg',
        permission: null
      },
      {
        title: 'MENU.USERS',
        icon: './assets/media/icons/duotune/communication/com006.svg',
        permission: ['core.auth.read', 'core.role.read'],
        submenu: [
          {
            title: 'MENU.USERS',
            icon: './assets/media/icons/duotune/communication/com014.svg',
            page: 'users',
            permission: ['core.auth.read']
          },
          {
            title: 'MENU.USER_ROLES',
            icon: './assets/media/icons/duotune/general/gen049.svg',
            page: '/roles',
            permission: ['core.role.read']
          },
          {
            title: 'MENU.USER_GRAPH',
            icon: './assets/media/icons/duotune/graphs/gra001.svg',
            page: '/user-graph',
            permission: ['core.role.read']
          }
        ]
      },
      {
        title: 'MENU.MUNICIPALITIES',
        icon: './assets/media/icons/duotune/general/gen001.svg',
        page: '/municipalities',
        permission: ['smartek.municipality.list']
      },
      {
        title: 'MENU.COMPANIES',
        icon: './assets/media/icons/duotune/finance/fin001.svg',
        page: '/companies',
        permission: ['smartek.company.list']
      },
      {
        title: 'MENU.BUILDINGS',
        icon: './assets/media/icons/duotune/maps/map008.svg',
        page: 'buildings',
        permission: ['smartek.building.list']
      },
      {
        title: 'MENU.EXTRA',
        icon: './assets/media/icons/duotune/general/gen024.svg',
        permission: ['core.auth.read', 'core.role.read'],
        submenu: [/*
          {
            title: 'MENU.BUILDING_CATEGORIES',
            icon: './assets/media/icons/duotune/maps/map008.svg',
            page: '/building-categories',
            permission: ['core.role.read']
          },*/
          {
            title: 'MENU.BUILDING_CONSUMPTION_DATA_TYPES',
            icon: './assets/media/icons/duotune/abstract/abs026.svg',
            page: '/building-consumption-data-types',
            permission: ['core.auth.read']
          },
          {
            title: 'MENU.CONNECTION_DATA_TYPES',
            icon: './assets/media/icons/duotune/communication/com014.svg',
            page: '/building-connection-types',
            permission: ['core.auth.read']
          },
          {
            title: 'MENU.EMISSION_FACTOR',
            icon: './assets/media/icons/duotune/abstract/abs017.svg',
            page: '/emission-factor',
            permission: ['core.auth.read']
          },
          {
            title: 'MENU.ENERGETIC_SIMULATION_MASTER_DATA',
            icon: './assets/media/icons/duotune/abstract/abs038.svg',
            page: '/energetic-simulation',
            permission: ['core.auth.read']
          }
        ]
      }
    ]
  };

  public static configs() {
    return this.defaults;
  }

  /*public static userConfigs(organisationType: string, id: number) {
    return {items: [
        {
          title: 'MENU.DASHBOARD',
          page: '/dashboard',
          icon: './assets/media/icons/duotune/art/art002.svg',
          permission: null
        },
        {
          title: 'USER_MENU.MAIN_DATA',
          icon: './assets/media/icons/duotune/files/fil012.svg',
          permission: organisationType === 'companies' ? ['smartek.company.get'] : ['smartek.municipality.get'],
          submenu: [
            {
              title: 'USER_MENU.ADD_DATA',
              icon: './assets/media/icons/duotune/files/fil005.svg',
              page: `/${organisationType}/update/${id}`,
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.ADD_SETTLEMENT_DATA',
                  icon: './assets/media/icons/duotune/maps/map007.svg',
                  page: `/${organisationType}/settlement-data/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_BUILDINGS',
                  icon: './assets/media/icons/duotune/maps/map008.svg',
                  page: `/buildings/navbar/main-data/create-building`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_GOALS',
                  icon: './assets/media/icons/duotune/general/gen020.svg',
                  page: `/building-goals`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_POPULATIONS_DATA',
                  icon: './assets/media/icons/duotune/maps/map003.svg',
                  page: `/${organisationType}/population-data/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_COMPANY_DATA',
                  icon: './assets/media/icons/duotune/files/fil012.svg',
                  page: '/dashboard/add-company-data',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.ALARM_LEVELS',
              icon: './assets/media/icons/duotune/general/gen007.svg',
              page: '/dashboard/alarm-levels',
              permission: null
            },
            {
              title: 'USER_MENU.PUBLIC_DATA_IMPORT',
              icon: './assets/media/icons/duotune/files/fil018.svg',
              page: '/dashboard/public-data-import',
              permission: null
            }
          ]
        },
        {
          title : 'USER_MENU.DOCUMENTS',
          icon: './assets/media/icons/duotune/files/fil016.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.MUNICIPALITY_DOCUMENTS',
              icon: './assets/media/icons/duotune/files/fil016.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.VIEW_STRATEGIES',
                  icon: './assets/media/icons/duotune/files/fil004.svg',
                  page: `/dashboard/view-strategies`,
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.NATIONAL',
              icon: './assets/media/icons/duotune/maps/map001.svg',
              page: '/dashboard/national',
              permission: null
            },
            {
              title: 'USER_MENU.INTERNATIONAL',
              icon: './assets/media/icons/duotune/maps/map009.svg',
              page: '/dashboard/international',
              permission: null
            },
            {
              title: 'USER_MENU.OTHER',
              icon: './assets/media/icons/duotune/abstract/abs028.svg',
              page: '/dashboard/other',
              permission: null
            }
          ]
        },
        {
          title : 'USER_MENU.ENVIRONMENTAL_CONDITION',
          icon: './assets/media/icons/duotune/maps/map008.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SETTLEMENT_DATA',
              icon: './assets/media/icons/duotune/maps/map002.svg',
              page: '/dashboard/env-cond-settlement-data',
              permission: null
            },
            {
              title: 'USER_MENU.SETTLEMENT_SETTINGS',
              icon: './assets/media/icons/duotune/general/gen019.svg',
              page: '/dashboard/env-cond-settlement-settings',
              permission: null
            },
            {
              title: 'USER_MENU.AIR_QUALITY',
              icon: './assets/media/icons/duotune/abstract/abs016.svg',
              page: '/dashboard/air-quality',
              permission: null
            },
            {
              title: 'USER_MENU.WATER_QUALITY',
              icon: './assets/media/icons/duotune/abstract/abs017.svg',
              page: '/dashboard/water-quality',
              permission: null
            },
            {
              title: 'USER_MENU.WEATHER_DATA',
              icon: './assets/media/icons/duotune/abstract/abs030.svg',
              page: '/dashboard/weather-data',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.ENERGY_UTILITIES',
          icon: './assets/media/icons/duotune/maps/map010.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SETTLEMENT_DATA',
              icon: './assets/media/icons/duotune/maps/map002.svg',
              page: '/dashboard/energy-utilities-settlement-data',
              permission: null
            },
            {
              title: 'USER_MENU.SETTLEMENT_SETTINGS',
              icon: './assets/media/icons/duotune/general/gen019.svg',
              page: '/dashboard/energy-utilities-settlement-settings',
              permission: null
            },
            {
              title: 'USER_MENU.BUILDINGS',
              icon: './assets/media/icons/duotune/maps/map008.svg',
              page: '/energy-utilities',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SHEET',
                  icon: './assets/media/icons/duotune/files/fil003.svg',
                  page: '/buildings/building-data-sheet',
                  permission: null
                },
                {
                  title: 'USER_MENU.VIEW_DATA',
                  icon: './assets/media/icons/duotune/general/gen005.svg',
                  page: '/buildings/building-view-data',
                  permission: null
                },
                {
                  title: 'USER_MENU.DATA_IMPORT',
                  icon: './assets/media/icons/duotune/files/fil010.svg',
                  page: '/buildings/building-data-import',
                  permission: null
                },
                {
                  title: 'USER_MENU.CONFIGURATION',
                  icon: './assets/media/icons/duotune/general/gen009.svg',
                  page: '/buildings/building-configuration',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.TRANSPORT',
              icon: './assets/media/icons/duotune/ecommerce/ecm006.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SHEET',
                  icon: './assets/media/icons/duotune/files/fil003.svg',
                  page: '/buildings/transport-data-sheet',
                  permission: null
                },
                {
                  title: 'USER_MENU.VIEW_DATA',
                  icon: './assets/media/icons/duotune/files/fil001.svg',
                  page: '/buildings/transport-view-data',
                  permission: null
                },
                {
                  title: 'USER_MENU.DATA_IMPORT',
                  icon: './assets/media/icons/duotune/general/gen005.svg',
                  page: '/buildings/transport-data-import',
                  permission: null
                },
                {
                  title: 'USER_MENU.CONFIGURATION',
                  icon: './assets/media/icons/duotune/general/gen009.svg',
                  page: '/buildings/transport-configuration',
                  permission: null
                }
              ]
            }
          ]
        },
        organisationType === 'municipalities' ? {
          title: 'USER_MENU.STRATEGIC_PLANNER',
          icon: './assets/media/icons/duotune/art/art002.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SECAP',
              icon: './assets/media/icons/duotune/art/art002.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.ADD_OBJECTIVES',
                  icon: './assets/media/icons/duotune/art/art005.svg',
                  page: `/secap/reports/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.CHECK_OBJECTIVES',
                  icon: './assets/media/icons/duotune/electronics/elc004.svg',
                  page: `/secap/reports/${id}`,
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.CLIMATE_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs021.svg',
              page: '/building-consumption-data-types',
              permission: null
            },
            {
              title: 'USER_MENU.ENERGY_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs002.svg',
              page: '/building-connection-types',
              permission: null
            },
            {
              title: 'USER_MENU.ITS',
              icon: './assets/media/icons/duotune/abstract/abs022.svg',
              page: '/emission-factor',
              permission: null
            },
            {
              title: 'USER_MENU.SUMP',
              icon: './assets/media/icons/duotune/abstract/abs026.svg',
              page: '/emission-factor',
              permission: null
            },
            {
              title: 'USER_MENU.UNDER2',
              icon: './assets/media/icons/duotune/abstract/abs028.svg',
              page: '/emission-factor',
              permission: null
            }
          ]
        }: {},
        {
          title: 'USER_MENU.SIMULATIONS',
          icon: './assets/media/icons/duotune/general/gen024.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.DEVELOPMENT_OPTIONS',
              icon: './assets/media/icons/duotune/general/gen002.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.SETTINGS',
                  icon: './assets/media/icons/duotune/general/gen019.svg',
                  page: '/dashboard/simulation-settings',
                  permission: null
                },
                {
                  title: 'USER_MENU.SIMULATION',
                  icon: './assets/media/icons/duotune/general/gen002.svg',
                  page: '/dashboard/simulation-simulation',
                  permission: null
                }
              ]
            }
          ]
        },
        {
          title: 'USER_MENU.DATA_PUBLICATION',
          icon: './assets/media/icons/duotune/general/gen005.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SHOW_PUBLIC_DATA',
              icon: './assets/media/icons/duotune/general/gen005.svg',
              page: '/dashboard/show-public-data',
              permission: null
            },
            {
              title: 'USER_MENU.PUBLIC_DATA_SETTINGS',
              icon: './assets/media/icons/duotune/files/fil016.svg',
              page: '/dashboard/public-data-settings',
              permission: null
            },
            {
              title: 'USER_MENU.SHOW_DATA',
              icon: './assets/media/icons/duotune/general/gen005.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SETTINGS',
                  icon: './assets/media/icons/duotune/files/fil025.svg',
                  page: '/dashboard/data-settings',
                  permission: null
                },
                {
                  title: 'USER_MENU.QUERY_DATA',
                  icon: './assets/media/icons/duotune/files/fil017.svg',
                  page: '/dashboard/query-data',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.EXPORT',
              icon: './assets/media/icons/duotune/files/fil017.svg',
              page: '/dashboard/data-publication-export',
              permission: null
            },
            {
              title: 'USER_MENU.REPORT',
              icon: './assets/media/icons/duotune/abstract/abs014.svg',
              page: '/dashboard/data-publication-report',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.HELP_AND_SEARCH',
          icon: './assets/media/icons/duotune/general/gen004.svg',
          page: '/dashboard/help-and-search',
          permission: null,
        },
        {
          title: 'USER_MENU.PROJECT_PLANNER',
          icon: './assets/media/icons/duotune/art/art002.svg',
          page: '/dashboard/project-planner',
          permission: null,
        }
      ]};
  }*/

  public static MunicipalityBaseConfigs() {
    return {
      items: [
        {
          title: 'MENU.DASHBOARD',
          page: 'dashboard',
          icon: './assets/media/icons/duotune/art/art002.svg',
          permission: null
        }
      ]
    };
  }

  public static AdminConfigs() {
    return {
      items: [
        {
          title: 'MENU.MUNICIPALITIES',
          icon: './assets/media/icons/duotune/general/gen001.svg',
          page: 'municipalities',
          permission: ['smartek.municipality.list']
        },
        {
          title: 'MENU.COMPANIES',
          icon: './assets/media/icons/duotune/finance/fin001.svg',
          page: 'companies',
          permission: ['smartek.company.list_hide']
        },
        {
          title: 'MENU.USERS',
          icon: './assets/media/icons/duotune/communication/com006.svg',
          permission: ['core.auth.read', 'core.role.read'],
          submenu: [
            {
              title: 'MENU.USERS',
              icon: './assets/media/icons/duotune/communication/com014.svg',
              page: 'users',
              permission: ['core.auth.read']
            },
            {
              title: 'MENU.USER_ROLES',
              icon: './assets/media/icons/duotune/general/gen049.svg',
              page: 'roles',
              permission: ['core.role.read']
            },
            {
              title: 'MENU.USER_GRAPH',
              icon: './assets/media/icons/duotune/graphs/gra001.svg',
              page: 'user-graph',
              permission: ['core.role.read']
            }
          ]
        },
        {
          title: 'MENU.EXTRA',
          icon: './assets/media/icons/duotune/general/gen024.svg',
          permission: ['core.auth.read', 'core.role.read'],
          submenu: [/*
            {
              title: 'MENU.BUILDING_CATEGORIES',
              icon: './assets/media/icons/duotune/maps/map008.svg',
              page: 'building-categories',
              permission: ['core.role.read']
            },*/
            {
              title: 'MENU.BUILDING_CONSUMPTION_DATA_TYPES',
              icon: './assets/media/icons/duotune/abstract/abs026.svg',
              page: 'building-consumption-data-types',
              permission: ['core.auth.read']
            },
            {
              title: 'MENU.CONNECTION_DATA_TYPES',
              icon: './assets/media/icons/duotune/communication/com014.svg',
              page: 'building-connection-types',
              permission: ['core.auth.read']
            },
            {
              title: 'MENU.EMISSION_FACTOR',
              icon: './assets/media/icons/duotune/abstract/abs017.svg',
              page: 'emission-factor',
              permission: ['core.auth.read']
            },
            {
              title: 'MENU.ENERGETIC_SIMULATION_MASTER_DATA',
              icon: './assets/media/icons/duotune/abstract/abs038.svg',
              page: 'energetic-simulation',
              permission: ['core.auth.read']
            }
          ]
        }
      ]
    }
  }

  public static MunicipalityUserConfigs(organisationType: string, id: number) {
    return {
      items: [
        {
          title: 'USER_MENU.MAIN_DATA',
          icon: './assets/media/icons/duotune/files/fil012.svg',
          permission: organisationType === 'companies' ? ['smartek.company.get'] : ['smartek.municipality.get'],
          page: `${organisationType}/update/${id}`,
        },
        {
          title: 'USER_MENU.BUILDINGS',
          icon: './assets/media/icons/duotune/maps/map008.svg',
          permission: ['smartek.building.list'],
          page: `buildings`,
        },
        {
          title: 'USER_MENU.ENVIRONMENTAL_DATA',
          icon: './assets/media/icons/duotune/abstract/abs030.svg',
          //page: '/dashboard/weather-data',
          permission: ['smartek.building.environmentaldata.aggregated'],
          submenu: [
            {
              title: 'USER_MENU.AIR_QUALITY',
              faIcon: 'fa-solid fa-smog',
              page: `environmental-data/air-quality/${id}`,
              permission: ['smartek.building.environmentaldata.aggregated']
            },
            {
              title: 'USER_MENU.WATER_QUALITY',
              faIcon: 'fa-solid fa-water',
              page: `environmental-data/water-quality/${id}`,
              permission: ['smartek.building.environmentaldata.aggregated']
            },
            {
              title: 'USER_MENU.SOIL_QUALITY',
              faIcon: 'fa-solid fa-seedling',
              permission: ['smartek.building.environmentaldata.aggregated'],
              page: `environmental-data/soil-quality/${id}`,
            },
            {
              title: 'USER_MENU.NOISE_POLLUTION',
              faIcon: 'fa-solid fa-ear-listen',
              permission: ['smartek.building.environmentaldata.aggregated'],
              page: `environmental-data/noise-pollution/${id}`,
            }
          ]
        },
        {
          title: 'USER_MENU.CONSUMPTION_DATA',
          icon: './assets/media/icons/duotune/general/gen005.svg',
          //page: '/consumption-data',
          permission: ['smartek.building.consumptiondata.aggregated', 'smartek.building.consumptiondata.admin.read'],
          submenu: [
            {
              title: 'USER_MENU.ELECTRIC_ENERGY_CONSUMPTION',
              faIcon: 'fa-solid fa-power-off',
              permission: ['smartek.building.consumptiondata.aggregated'],
              page: `consumption-data/electric-energy-consumption/${id}`
            },
            {
              title: 'USER_MENU.GAS_ENERGY_CONSUMPTION',
              faIcon: 'fa-solid fa-solid fa-plug-circle-bolt',
              permission: ['smartek.building.consumptiondata.aggregated'],
              page: `consumption-data/gas-energy-consumption/${id}`
            },
            {
              title: 'USER_MENU.WATER_CONSUMPTION',
              faIcon: 'fa-solid fa-faucet-drip',
              permission: ['smartek.building.consumptiondata.aggregated'],
              page: `consumption-data/water-consumption/${id}`
            },
            {
              title: 'USER_MENU.WASTE_MANAGEMENT',
              faIcon: 'fa-solid fa-trash-can',
              permission: ['smartek.building.consumptiondata.aggregated'],
              page: `consumption-data/waste-management/${id}`
            },
            {
              title: 'USER_MENU.ENERGY_GENERATION',
              faIcon: 'fa-solid fa-solar-panel',
              permission: ['smartek.building.consumptiondata.aggregated'],
              page: `consumption-data/energy-generation/${id}`
            },
          ]
        },
        {
          title: 'USER_MENU.STRATEGIC_PLANNER',
          icon: './assets/media/icons/duotune/files/fil004.svg',
          permission: ['smartek.strategicplanner', 'smartek.secap.read'],
          submenu: [
            {
              title: 'USER_MENU.SECAP',
              icon: './assets/media/icons/duotune/art/art002.svg',
              permission: ['smartek.strategicplanner', 'smartek.secap.read'],
              page: `secap/report/${id}`
            },
            {
              title: 'USER_MENU.CLIMATE_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs021.svg',
              page: 'climate-strategy',
              permission: ['smartek.strategicplanner']
            },
            {
              title: 'USER_MENU.ENERGY_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs002.svg',
              page: 'energy-strategy',
              permission: ['smartek.strategicplanner']
            },
            {
              title: 'USER_MENU.REZILIENCIA',
              icon: './assets/media/icons/duotune/abstract/abs022.svg',
              page: 'resilience',
              permission: ['smartek.strategicplanner']
            },
            {
              title: 'USER_MENU.ENVIRONMENTAL_PROTECTION',
              icon: './assets/media/icons/duotune/abstract/abs026.svg',
              page: 'environmental-protection',
              permission: ['smartek.strategicplanner']
            },
            {
              title: 'USER_MENU.SUSTAINABILITY',
              icon: './assets/media/icons/duotune/abstract/abs028.svg',
              page: 'sustainability',
              permission: ['smartek.strategicplanner']
            }
          ]
        },
        {
          title: 'USER_MENU.PROJECTS',
          icon: './assets/media/icons/duotune/art/art002.svg',
          page: 'tenders',
          permission: ['smartek.building.tender.read'],
        },
        {
          title: 'USER_MENU.SMART_MANAGEMENT',
          icon: './assets/media/icons/duotune/electronics/elc004.svg',
          permission: ['smartek.smartmanagement'],
          submenu: [
            /*{
              title: 'USER_MENU.ENERGY_CONSUMPTION_OPTIMALISATION',
              icon: './assets/media/icons/duotune/general/gen010.svg',
              page: `smart-management/consumption-optimalization`,
              permission: ['smartek.smartmanagement']
            },
            {
              title: 'USER_MENU.INTELLIGENT_TIMING',
              icon: './assets/media/icons/duotune/general/gen013.svg',
              page: `smart-management/intelligent-timing`,
              permission: ['smartek.smartmanagement']
            },
            {
              title: 'USER_MENU.ENERGY_PRODUCTION',
              icon: './assets/media/icons/duotune/general/gen009.svg',
              page: `smart-management/energy-production`,
              permission: ['smartek.smartmanagement']
            },
            {
              title: 'USER_MENU.DATA_ANALYSIS',
              icon: './assets/media/icons/duotune/electronics/elc004.svg',
              page: `smart-management/data-analysis`,
              permission: ['smartek.smartmanagement']
            },*/
            {
              title: 'USER_MENU.SIMULATIONS',
              icon: './assets/media/icons/duotune/abstract/abs023.svg',
              page: `smart-management/simulations`,
              permission: ['smartek.smartmanagement']
            },
            {
              title: 'USER_MENU.SIMULATIONS_RESILIENCE',
              icon: './assets/media/icons/duotune/abstract/abs022.svg',
              page: `smart-management/simulations`,
              permission: ['smartek.smartmanagement']
            }
          ]
        },
        {
          title: 'USER_MENU.LIBRARY',
          icon: './assets/media/icons/duotune/coding/cod002.svg',
          permission: ['smartek.library',
            'smartek.municipality.document.download',
            'smartek.municipality.document.category.list',
            'smartek.municipality.document.category.get',
            'smartek.municipality.document.tag.list',
            'smartek.municipality.document.tag.search',
            'smartek.municipality.document.tag.get',
          ],
          page: `library`,
        },
        {
          title: 'USER_MENU.SOCIAL_DIALOG',
          icon: './assets/media/icons/duotune/communication/com007.svg',
          permission: ['smartek.socialdialog',
            'smartek.socialdialog.mainthread.list',
            'smartek.socialdialog.subthread.list'],
          page: `social-dialog`,
        }
      ]
    };
  }

  public static CompanyUserConfigs(organisationType: string, id: number) {
    return {
      items: [
        {
          title: 'MENU.DASHBOARD',
          page: '/dashboard',
          icon: './assets/media/icons/duotune/art/art002.svg',
          permission: null
        },
        {
          title: 'USER_MENU.MAIN_DATA',
          icon: './assets/media/icons/duotune/files/fil012.svg',
          permission: organisationType === 'companies' ? ['smartek.company.get'] : ['smartek.municipality.get'],
          submenu: [
            {
              title: 'USER_MENU.ADD_DATA',
              icon: './assets/media/icons/duotune/files/fil005.svg',
              page: `${organisationType}/update/${id}`,
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.ADD_SETTLEMENT_DATA',
                  icon: './assets/media/icons/duotune/maps/map007.svg',
                  page: `${organisationType}/settlement-data/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_BUILDINGS',
                  icon: './assets/media/icons/duotune/maps/map008.svg',
                  page: `buildings/navbar/main-data/create-building`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_GOALS',
                  icon: './assets/media/icons/duotune/general/gen020.svg',
                  page: `building-goals`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_POPULATIONS_DATA',
                  icon: './assets/media/icons/duotune/maps/map003.svg',
                  page: `${organisationType}/population-data/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.ADD_COMPANY_DATA',
                  icon: './assets/media/icons/duotune/files/fil012.svg',
                  page: 'dashboard/add-company-data',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.ALARM_LEVELS',
              icon: './assets/media/icons/duotune/general/gen007.svg',
              page: 'dashboard/alarm-levels',
              permission: null
            },
            {
              title: 'USER_MENU.PUBLIC_DATA_IMPORT',
              icon: './assets/media/icons/duotune/files/fil018.svg',
              page: 'dashboard/public-data-import',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.DOCUMENTS',
          icon: './assets/media/icons/duotune/files/fil016.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.MUNICIPALITY_DOCUMENTS',
              icon: './assets/media/icons/duotune/files/fil016.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.VIEW_STRATEGIES',
                  icon: './assets/media/icons/duotune/files/fil004.svg',
                  page: `dashboard/view-strategies`,
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.NATIONAL',
              icon: './assets/media/icons/duotune/maps/map001.svg',
              page: 'dashboard/national',
              permission: null
            },
            {
              title: 'USER_MENU.INTERNATIONAL',
              icon: './assets/media/icons/duotune/maps/map009.svg',
              page: 'dashboard/international',
              permission: null
            },
            {
              title: 'USER_MENU.OTHER',
              icon: './assets/media/icons/duotune/abstract/abs028.svg',
              page: 'dashboard/other',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.ENVIRONMENTAL_CONDITION',
          icon: './assets/media/icons/duotune/maps/map008.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SETTLEMENT_DATA',
              icon: './assets/media/icons/duotune/maps/map002.svg',
              page: 'dashboard/env-cond-settlement-data',
              permission: null
            },
            {
              title: 'USER_MENU.SETTLEMENT_SETTINGS',
              icon: './assets/media/icons/duotune/general/gen019.svg',
              page: 'dashboard/env-cond-settlement-settings',
              permission: null
            },
            {
              title: 'USER_MENU.AIR_QUALITY',
              icon: './assets/media/icons/duotune/abstract/abs016.svg',
              page: 'dashboard/air-quality',
              permission: null
            },
            {
              title: 'USER_MENU.WATER_QUALITY',
              icon: './assets/media/icons/duotune/abstract/abs017.svg',
              page: 'dashboard/water-quality',
              permission: null
            },
            {
              title: 'USER_MENU.WEATHER_DATA',
              icon: './assets/media/icons/duotune/abstract/abs030.svg',
              page: 'dashboard/weather-data',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.ENERGY_UTILITIES',
          icon: './assets/media/icons/duotune/maps/map010.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SETTLEMENT_DATA',
              icon: './assets/media/icons/duotune/maps/map002.svg',
              page: 'dashboard/energy-utilities-settlement-data',
              permission: null
            },
            {
              title: 'USER_MENU.SETTLEMENT_SETTINGS',
              icon: './assets/media/icons/duotune/general/gen019.svg',
              page: 'dashboard/energy-utilities-settlement-settings',
              permission: null
            },
            {
              title: 'USER_MENU.BUILDINGS',
              icon: './assets/media/icons/duotune/maps/map008.svg',
              page: 'energy-utilities',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SHEET',
                  icon: './assets/media/icons/duotune/files/fil003.svg',
                  page: 'buildings/building-data-sheet',
                  permission: null
                },
                {
                  title: 'USER_MENU.VIEW_DATA',
                  icon: './assets/media/icons/duotune/general/gen005.svg',
                  page: 'buildings/building-view-data',
                  permission: null
                },
                {
                  title: 'USER_MENU.DATA_IMPORT',
                  icon: './assets/media/icons/duotune/files/fil010.svg',
                  page: 'buildings/building-data-import',
                  permission: null
                },
                {
                  title: 'USER_MENU.CONFIGURATION',
                  icon: './assets/media/icons/duotune/general/gen009.svg',
                  page: 'buildings/building-configuration',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.TRANSPORT',
              icon: './assets/media/icons/duotune/ecommerce/ecm006.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SHEET',
                  icon: './assets/media/icons/duotune/files/fil003.svg',
                  page: 'buildings/transport-data-sheet',
                  permission: null
                },
                {
                  title: 'USER_MENU.VIEW_DATA',
                  icon: './assets/media/icons/duotune/files/fil001.svg',
                  page: 'buildings/transport-view-data',
                  permission: null
                },
                {
                  title: 'USER_MENU.DATA_IMPORT',
                  icon: './assets/media/icons/duotune/general/gen005.svg',
                  page: 'buildings/transport-data-import',
                  permission: null
                },
                {
                  title: 'USER_MENU.CONFIGURATION',
                  icon: './assets/media/icons/duotune/general/gen009.svg',
                  page: 'buildings/transport-configuration',
                  permission: null
                }
              ]
            }
          ]
        },
        organisationType === 'municipalities' ? {
          title: 'USER_MENU.STRATEGIC_PLANNER',
          icon: './assets/media/icons/duotune/art/art002.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SECAP',
              icon: './assets/media/icons/duotune/art/art002.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.ADD_OBJECTIVES',
                  icon: './assets/media/icons/duotune/art/art005.svg',
                  page: `secap/reports/${id}`,
                  permission: null
                },
                {
                  title: 'USER_MENU.CHECK_OBJECTIVES',
                  icon: './assets/media/icons/duotune/electronics/elc004.svg',
                  page: `secap/reports/${id}`,
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.CLIMATE_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs021.svg',
              page: 'building-consumption-data-types',
              permission: null
            },
            {
              title: 'USER_MENU.ENERGY_STRATEGY',
              icon: './assets/media/icons/duotune/abstract/abs002.svg',
              page: 'building-connection-types',
              permission: null
            },
            {
              title: 'USER_MENU.ITS',
              icon: './assets/media/icons/duotune/abstract/abs022.svg',
              page: 'emission-factor',
              permission: null
            },
            {
              title: 'USER_MENU.SUMP',
              icon: './assets/media/icons/duotune/abstract/abs026.svg',
              page: 'emission-factor',
              permission: null
            },
            {
              title: 'USER_MENU.UNDER2',
              icon: './assets/media/icons/duotune/abstract/abs028.svg',
              page: 'emission-factor',
              permission: null
            }
          ]
        } : {},
        {
          title: 'USER_MENU.SIMULATIONS',
          icon: './assets/media/icons/duotune/general/gen024.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.DEVELOPMENT_OPTIONS',
              icon: './assets/media/icons/duotune/general/gen002.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.SETTINGS',
                  icon: './assets/media/icons/duotune/general/gen019.svg',
                  page: 'dashboard/simulation-settings',
                  permission: null
                },
                {
                  title: 'USER_MENU.SIMULATION',
                  icon: './assets/media/icons/duotune/general/gen002.svg',
                  page: 'dashboard/simulation-simulation',
                  permission: null
                }
              ]
            }
          ]
        },
        {
          title: 'USER_MENU.DATA_PUBLICATION',
          icon: './assets/media/icons/duotune/general/gen005.svg',
          permission: null,
          submenu: [
            {
              title: 'USER_MENU.SHOW_PUBLIC_DATA',
              icon: './assets/media/icons/duotune/general/gen005.svg',
              page: 'dashboard/show-public-data',
              permission: null
            },
            {
              title: 'USER_MENU.PUBLIC_DATA_SETTINGS',
              icon: './assets/media/icons/duotune/files/fil016.svg',
              page: 'dashboard/public-data-settings',
              permission: null
            },
            {
              title: 'USER_MENU.SHOW_DATA',
              icon: './assets/media/icons/duotune/general/gen005.svg',
              permission: null,
              submenu: [
                {
                  title: 'USER_MENU.DATA_SETTINGS',
                  icon: './assets/media/icons/duotune/files/fil025.svg',
                  page: 'dashboard/data-settings',
                  permission: null
                },
                {
                  title: 'USER_MENU.QUERY_DATA',
                  icon: './assets/media/icons/duotune/files/fil017.svg',
                  page: 'dashboard/query-data',
                  permission: null
                }
              ]
            },
            {
              title: 'USER_MENU.EXPORT',
              icon: './assets/media/icons/duotune/files/fil017.svg',
              page: 'dashboard/data-publication-export',
              permission: null
            },
            {
              title: 'USER_MENU.REPORT',
              icon: './assets/media/icons/duotune/abstract/abs014.svg',
              page: 'dashboard/data-publication-report',
              permission: null
            }
          ]
        },
        {
          title: 'USER_MENU.HELP_AND_SEARCH',
          icon: './assets/media/icons/duotune/general/gen004.svg',
          page: 'dashboard/help-and-search',
          permission: null,
        },
        {
          title: 'USER_MENU.PROJECT_PLANNER',
          icon: './assets/media/icons/duotune/art/art002.svg',
          page: 'dashboard/project-planner',
          permission: null,
        }
      ]
    };
  }
}
