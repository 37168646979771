import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, iif, Observable, of, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {MunicipalityService} from "./municipality.service";
import {CompanyService} from "./company.service";
import {ExtendedUserService} from "./extended-user.service";
import {CurrentUserDashboardModel, DashboardUserRoles} from "../model/current-user-dashboard.model";

@Injectable({
  providedIn: 'root'
})
export class AsideMenuResolverService{

  currentUserDashboard: BehaviorSubject<CurrentUserDashboardModel>;
  isAdmin: BehaviorSubject<boolean>;

  constructor(private municipalityService: MunicipalityService,
              private companyService: CompanyService,
              private extendedUserService: ExtendedUserService) { }

  getUserMenuSettings() {
    return this.extendedUserService.getUserWithRole().pipe(switchMap(user => {
      return iif(() => user?.roles.some(r => r.name.includes(DashboardUserRoles.System) && !r.name.includes(DashboardUserRoles.Mayor)) || false,
        of(undefined)
          .pipe(tap(() => {
            this.isAdmin = new BehaviorSubject<boolean>(true);
            this.currentUserDashboard = new BehaviorSubject<CurrentUserDashboardModel>({role: DashboardUserRoles.System, municipalityId: undefined, companyId: undefined})
          })),
        forkJoin([this.municipalityService.readMyMunicipalities(true), this.companyService.readMyCompanies(true)])
          .pipe(tap(([municipalities, companies]) => {
            this.isAdmin = new BehaviorSubject<boolean>(false);
              const currentUserRole = user.roles.some(role => role.name === DashboardUserRoles.Mayor) ? DashboardUserRoles.Mayor : DashboardUserRoles.User;
            if(municipalities.length === 1 && companies.length === 0){
              this.currentUserDashboard = new BehaviorSubject<CurrentUserDashboardModel>({role: currentUserRole, municipalityId: municipalities[0].id, companyId: undefined})
            }
            else if(companies.length === 1 && municipalities.length === 0) {
              this.currentUserDashboard = new BehaviorSubject<CurrentUserDashboardModel>({role: currentUserRole, municipalityId: undefined, companyId: companies[0].id})
            } else {
              this.currentUserDashboard = new BehaviorSubject<CurrentUserDashboardModel>({role: currentUserRole, municipalityId: undefined, companyId: undefined})
            }
          })))
    }));
  }

  changeCurrentUserData(isMunicipality: boolean, id: number | undefined) {
    isMunicipality ?
    this.currentUserDashboard.next({role: DashboardUserRoles.System, municipalityId: id, companyId: undefined}) :
      this.currentUserDashboard.next({role: DashboardUserRoles.System, municipalityId: undefined, companyId: id})
  }

  get isAdminValue(): boolean {
    return this.isAdmin.value;
  }

  getAdminDataObs(): Observable<boolean> {
    return this.isAdmin.asObservable();
  }
}
