export class CurrentUserDashboardModel {
  role: DashboardUserRoles;
  municipalityId: number | undefined;
  companyId: number | undefined
}

export enum DashboardUserRoles  {
  System = 'System',
  Mayor = 'Mayor',
  User = 'User'
}
