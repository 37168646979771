import {Injectable} from '@angular/core';
import {AuthService, RequestService} from "pemikdev-project-lib";
import {map} from "rxjs/operators";
import { Observable, of} from "rxjs";
import {MunicipalityModel} from "../model/municipality.model";
import {MunicipalitySettlementModel} from "../model/municipality-settlement.model";
import {MunicipalityContactModel} from "../model/municipality-contact.model";
import {BuildingTenderModel} from "../model/building-tender.model";
import {HttpClient, HttpHeaders} from "@angular/common/http";

const CREATE_MUNICIPALITY = '/api/municipality/create';
const UPDATE_MUNICIPALITY = '/api/municipality/update';
const READ_MUNICIPALITIES = '/api/municipality/read';

const READ_MY_MUNICIPALITIES = '/api/municipality/my';
const READ_MUNICIPALITY_BY_ID = '/api/municipality/';
const READ_MY_MUNICIPALITY_BY_ID = '/api/municipality/my/';

const READ_CONTACT_BY_ID = '/api/municipality/contact/';
const CREATE_CONTACT = '/api/municipality/contact/create';
const UPDATE_CONTACT = '/api/municipality/contact/update';
const DELETE_CONTACT = '/api/municipality/contact/';

const READ_MY_CONTACT_BY_ID = '/api/municipality/contact/my/';
const CREATE_MY_CONTACT = '/api/municipality/contact/my/';
const UPDATE_MY_CONTACT = '/api/municipality/contact/my/';
const DELETE_MY_CONTACT = '/api/municipality/contact/my/';

const CREATE_SETTLEMENT = '/api/municipality/settlement/create';
const UPDATE_SETTLEMENT = '/api/municipality/settlement/update';
const READ_SETTLEMENT_BY_ID = '/api/municipality/settlement/';

const READ_USER_CONNECTIONS = '/api/municipality/userConnections/';
const READ_MY_USER_CONNECTIONS = '/api/municipality/userConnections/my/';

const CREATE_USER_CONNECTION = '/api/municipality/';
const CREATE_MY_USER_CONNECTION = '/api/municipality/my/';

const REMOVE_USER_CONNECTION = '/api/municipality/';
const REMOVE_MY_USER_CONNECTION = '/api/municipality/my/';

const CREATE_BUILDING_CONNECTION = '/api/municipality/';
const CREATE_MY_BUILDING_CONNECTION = '/api/municipality/my/';

const REMOVE_BUILDING_CONNECTION = '/api/municipality/building/connection/';
const REMOVE_MY_BUILDING_CONNECTION = '/api/municipality/my/building/connection/';

const READ_BUILDINGS = '/api/building/connection/municipality';
const READ_MY_BUILDINGS = '/api/building/my/connection/municipality/';

const READ_TENDERS = '/api/municipality/';
const ADD_COVER_IMAGE = '/api/municipality/my/';
const ADD_COVER_IMAGE_ADMIN = '/api/municipality/';

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {

  municipalities: MunicipalityModel[];
  tenders: BuildingTenderModel[];

  constructor(private requestService: RequestService,
              private http: HttpClient,
              private authService: AuthService) {
  }

  readMunicipalities(reload = false): Observable<MunicipalityModel[]> {
    if (this.municipalities && !reload) {
      return of(this.municipalities);
    }
    return this.requestService.get<any>(READ_MUNICIPALITIES).pipe(
      map(res => {
        this.municipalities = res.result;
        return res.result;
      })
    );
  }

  readMyMunicipalities(reload = false): Observable<MunicipalityModel[]> {
    if (this.municipalities && !reload) {
      return of(this.municipalities);
    }
    return this.requestService.get<any>(READ_MY_MUNICIPALITIES).pipe(
      map(res => {
        this.municipalities = res.result;
        return res.result;
      })
    );
  }

  readMunicipalitySettlementById(municipalityId: number): Observable<MunicipalitySettlementModel[]> {
    return this.requestService.get<any>(READ_SETTLEMENT_BY_ID + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMunicipalityById(id: number): Observable<MunicipalityModel> {
    return this.requestService.get<any>(READ_MUNICIPALITY_BY_ID + id).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyMunicipalityById(id: number): Observable<MunicipalityModel> {
    return this.requestService.get<any>(READ_MY_MUNICIPALITY_BY_ID + id).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  createMunicipality(data: MunicipalityModel): Observable<MunicipalityModel> {
    return this.requestService.post<any>(CREATE_MUNICIPALITY, data).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  updateMunicipality(data: any) {
    return this.requestService.put(UPDATE_MUNICIPALITY, data);
  }

  createContact(contact: any): Observable<MunicipalityContactModel> {
    return this.requestService.post(CREATE_CONTACT, contact);
  }

  updateContact(contact: MunicipalityContactModel): Observable<MunicipalityContactModel> {
    return this.requestService.put(UPDATE_CONTACT, contact);
  }

  deleteContact(municipalityId: number, contactId: number): Observable<any> {
    return this.requestService.delete(DELETE_CONTACT + municipalityId + '/contact/' + contactId);
  }

  createMyContact(municipalityId: number, contact: any): Observable<MunicipalityContactModel> {
    return this.requestService.post(CREATE_MY_CONTACT + municipalityId, contact);
  }

  updateMyContact(municipalityId: number, contact: MunicipalityContactModel): Observable<MunicipalityContactModel> {
    return this.requestService.put(UPDATE_MY_CONTACT + municipalityId, contact);
  }

  deleteMyContact(municipalityId: number, contactId: number): Observable<any> {
    return this.requestService.delete(DELETE_MY_CONTACT + municipalityId + '/contact/' + contactId);
  }

  readMunicipalityContactById(id: number): Observable<MunicipalityContactModel[]> {
    return this.requestService.get<any>(READ_CONTACT_BY_ID + id).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyMunicipalityContactById(id: number): Observable<MunicipalityContactModel[]> {
    return this.requestService.get<any>(READ_MY_CONTACT_BY_ID + id).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  createSettlement(data: any): Observable<MunicipalitySettlementModel> {
    return this.requestService.post(CREATE_SETTLEMENT, data);
  }

  updateSettlement(data: MunicipalitySettlementModel) {
    return this.requestService.put(UPDATE_SETTLEMENT, data);
  }

  readMunicipalityUserConnections(municipalityId: number): Observable<any> {
    return this.requestService.get<any>(READ_USER_CONNECTIONS + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyMunicipalityUserConnections(municipalityId: number): Observable<any> {
    return this.requestService.get<any>(READ_MY_USER_CONNECTIONS + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  createUserConnection(municipalityId: number, userId: number): Observable<any> {
    return this.requestService.post(CREATE_USER_CONNECTION + municipalityId + '/user/' + userId + '/connectedTo', null);
  }

  createMyUserConnection(municipalityId: number, userId: number): Observable<any> {
    return this.requestService.post(CREATE_MY_USER_CONNECTION + municipalityId + '/user/' + userId + '/connectedTo', null);
  }

  removeUserConnection(municipalityId: number, userId: number): Observable<any> {
    return this.requestService.delete(REMOVE_USER_CONNECTION + municipalityId + '/connection/user/' + userId + '/type/connectedTo', null);
  }

  removeMyUserConnection(municipalityId: number, userId: number): Observable<any> {
    return this.requestService.delete(REMOVE_MY_USER_CONNECTION + municipalityId + '/connection/user/' + userId + '/type/connectedTo', null);
  }

  createBuildingConnection(municipalityId: number, buildingId: number): Observable<any> {
    return this.requestService.post(CREATE_BUILDING_CONNECTION + municipalityId + '/connection/' + buildingId, null);
  }

  createMyBuildingConnection(municipalityId: number, buildingId: number): Observable<any> {
    return this.requestService.post(CREATE_MY_BUILDING_CONNECTION + municipalityId + '/connection/' + buildingId, null);
  }

  removeBuildingConnection(connectionId: number): Observable<any> {
    return this.requestService.delete(REMOVE_BUILDING_CONNECTION + connectionId, null);
  }

  removeMyBuildingConnection(connectionId: number, municipalityId: number): Observable<any> {
    return this.requestService.delete(REMOVE_MY_BUILDING_CONNECTION + connectionId + '/' + municipalityId, null);
  }

  readBuildings(): Observable<any> {
    return this.requestService.get<any>(READ_BUILDINGS).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyBuildings(municipalityId: number): Observable<any> {
    return this.requestService.get<any>(READ_MY_BUILDINGS + municipalityId).pipe(
      map(res => {
        return res.result;
      })
    );
  }

  readMyTenders(municipalityId: number | undefined, reload = false): Observable<BuildingTenderModel[]> {
    if (this.tenders && !reload) {
      return of(this.tenders);
    }
    return this.requestService.get<any>(READ_TENDERS + municipalityId + '/tenders').pipe(
      map(res => {
        this.tenders = res.result;
        return res.result;
      })
    );
  }

  addMunicipalityCoverImage(municipalityId: number, file: any, isAdmin: boolean) {
    const formData = new FormData();
    formData.append('image', file, file.name);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Authorization: 'Bearer ' + this.authService.getToken(),
      // 'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'multipart/form-data',
      'X-File-Upload': 'true'
    });
    return this.http.post(`${isAdmin ? ADD_COVER_IMAGE_ADMIN : ADD_COVER_IMAGE}${municipalityId}/coverimage`, formData, {headers});
  }
}
