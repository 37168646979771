import {NgModule, APP_INITIALIZER, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import {registerLocaleData} from "@angular/common";
import hu from '@angular/common/locales/hu';
import {defineLocale, huLocale} from "ngx-bootstrap/chronos";
import {NgxSpinnerModule} from "ngx-spinner";
import {HttpRequestInterceptorService} from "./smartek/service/http-request-interceptor.service";
import {NotifierModule} from "angular-notifier";
import {CommonEnvModule} from "pemikdev-project-lib";

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {NgChartsModule} from "ng2-charts";
import {ModalModule} from "ngx-bootstrap/modal";
import {AsideMenuResolverService} from "./smartek/service/aside-menu-resolver.service";
import {ColorPickerModule} from "ngx-color-picker";

// #fake-end#

function appInitializer(asideMenuService: AsideMenuResolverService): () => Promise<void>  {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      asideMenuService.getUserMenuSettings().subscribe().add(resolve);
    });
  };
}

registerLocaleData(hu);
defineLocale('hu', huLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    CommonEnvModule.forRoot(environment),
    NgbModule,
    NgxSpinnerModule,
    NotifierModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken, // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    NgChartsModule,
    ModalModule.forRoot(),
    ColorPickerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AsideMenuResolverService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "hu"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
